import { useState } from "react"
import type { FetchSignerResult, Signer } from "@wagmi/core"
import { isAddress } from "@ethersproject/address"
import { useSwitchNetwork, useAccount } from "wagmi"

import type { AssetId } from "query/graphql"
import { useCreateDelegationAttemptMutation, TokenType } from "query/graphql"
import type { UseCorrectChainErrors } from "web3/helpers/chain"
import { getCorrectChain } from "web3/helpers/chain"
import { getErc20 } from "web3/hooks/useErc20"
import { getErc721 } from "web3/hooks/useErc721"
import { useTransaction } from "web3/providers/TransactionProvider"
import type {
  TransactionOnMining,
  TransactionOnSuccess,
  TransactionStatus,
} from "web3/types/transaction"
import { TransactionToastState } from "web3/types/transaction"
import { useToast } from "common/hooks/useToast"
import { useNavegableModal } from "common/hooks/useNavegableModal"
import { useAnalytics } from "common/hooks/useAnalytics"
import { UseDirectDelegationErrors } from "delegation/constants/delegation-error"
import { getChainIdParams } from "web3/helpers/chainId"
import {
  getChainReferenceName,
  getMainnetReference,
} from "web3/helpers/chainReference"
import {
  accountIdToChainId,
  addressToAccountId,
  chainIdToChainReference,
} from "web3/helpers/transformers"
import { useSafeTransaction } from "safe/hooks/useSafeTransaction"
import { useMe } from "user/providers/MeProvider"
import { getAssetIdParams } from "web3/helpers/assetId"
import { getAaveToken } from "web3/hooks/useAaveToken"
import { getAaveStaked } from "web3/hooks/useAaveStaked"

type Error = UseCorrectChainErrors | UseDirectDelegationErrors

type Metadata = {
  tokenId: string
  chainId: string
  delegateAddress: string
}

type Values = {
  status: TransactionStatus<Metadata>
  error?: Error
  directDelegation: (values: {
    signer?: FetchSignerResult<Signer>
    tokenId?: AssetId
    delegateAddress?: string
  }) => Promise<void>
}

type Props = {
  chainReference?: number
  openPendingTransaction?: () => void
  onMiningTransaction?: () => void
}

export const useDirectDelegation = ({
  chainReference,
  openPendingTransaction,
  onMiningTransaction,
}: Props): Values => {
  const [error, setError] = useState<Error | undefined>(undefined)

  const { address } = useAccount()
  const me = useMe()
  const { switchNetwork } = useSwitchNetwork()
  const { toast } = useToast()
  const { onClose } = useNavegableModal()
  const { saveDirectDelegation } = useAnalytics()

  const canUseSafeTx = me?.type === "SAFE"

  const { mutate: createDelegationAttempt } =
    useCreateDelegationAttemptMutation()

  const onSuccess: TransactionOnSuccess<Metadata> = ({
    metadata,
    transaction,
  }) => {
    const { hash: txHash, from } = transaction
    const { delegateAddress, tokenId, chainId } = metadata
    const { address: tokenAddress } = getAssetIdParams(tokenId)

    saveDirectDelegation({
      from,
      txHash,
      chainId,
      delegatee: delegateAddress,
      contractAddress: tokenAddress,
    })
  }

  const {
    proposeMultiGnosisTransaction,
    buildSafeTransaction,
    onPendingSafeTx,
  } = useSafeTransaction(
    {
      safeId: me?.id as string,
      accountAddress: address,
    },
    { enabled: canUseSafeTx },
  )

  const onMining: TransactionOnMining<Metadata> = ({
    transaction,
    metadata,
  }) => {
    onClose()

    if (onMiningTransaction) {
      onMiningTransaction()
    }

    if (address) {
      const { hash } = transaction
      const { delegateAddress, chainId, tokenId } = metadata
      const chainReference = chainIdToChainReference(chainId)
      const txID = addressToAccountId(hash, chainReference)
      const delegateeId = addressToAccountId(delegateAddress, chainReference)
      const delegatorId = addressToAccountId(address, getMainnetReference())

      if (txID && tokenId && delegateeId) {
        createDelegationAttempt({
          delegatorId,
          delegateeId,
          tokenId,
          txID,
        })
      }
    }
  }

  const { status, send, setMetadata } = useTransaction({
    on: {
      [TransactionToastState.Mining]: onMining,
      [TransactionToastState.Success]: onSuccess,
    },
  })

  const directDelegation = async ({
    signer,
    tokenId,
    delegateAddress,
  }: {
    signer?: FetchSignerResult<Signer>
    tokenId?: string
    delegateAddress?: string
  }): Promise<void> => {
    if (!tokenId) {
      setError(UseDirectDelegationErrors.Undefinedtokend)

      return
    }

    const {
      chainId,
      tokenType,
      address: tokenAddress,
    } = getAssetIdParams(tokenId)

    const { reference } = getChainIdParams(chainId)
    const chainReferenceName = getChainReferenceName(reference)

    if (!signer) {
      setError(UseDirectDelegationErrors.Signernotfound)

      toast({
        status: "warning",
        title: "Wallet disconnected",
        description: `You must connect your wallet to delegate`,
        duration: 5000,
      })

      return
    }

    if (!delegateAddress || !isAddress(delegateAddress)) {
      setError(UseDirectDelegationErrors.Invaliddelegateeaddress)

      toast({
        status: "warning",
        title: "Invalid address",
        description: "The address you are trying to delegate to is invalid",
        duration: 5000,
      })

      return
    }

    const { isCorrectChain, error: useCorrectChainErrors } = getCorrectChain({
      chainId,
      chainReference,
    })

    const handlePendingSafeTx = (values: Record<string, any>) => {
      if (
        !address ||
        !me?.id ||
        !values.safe ||
        !values.safeTxHash ||
        !values.tokenId
      ) {
        return
      }

      const chainId = accountIdToChainId(values.tokenId)
      const chainReference = chainIdToChainReference(chainId)
      const txID = addressToAccountId(values.safeTxHash, chainReference)
      const delegateeId = addressToAccountId(
        values.delegateAddress,
        chainReference,
      )

      if (txID && tokenId && delegateeId) {
        createDelegationAttempt({
          delegatorId: me.id,
          delegateeId,
          tokenId,
          txID,
        })
      }
    }

    if (!isCorrectChain) {
      setError(UseDirectDelegationErrors.Nonmatchingchain)

      toast({
        status: "warning",
        title: "Incorrect network",
        description: `You must be connected to the ${chainReferenceName} network to delegate on this governace`,
        duration: 5000,
      })

      switchNetwork?.(reference)

      return
    }

    if (useCorrectChainErrors !== undefined) {
      setError(useCorrectChainErrors)

      throw new Error(
        `useDirectDelegation => "chainId" or "chainReference" or both, are incorrect.
         This should not be happening as they are being gathered from the server and the provider`,
      )
    }

    if (!isAddress(tokenAddress)) {
      setError(UseDirectDelegationErrors.Invalidtokenaddress)

      return
    }

    setMetadata({ delegateAddress, chainId, tokenId })
    setError(undefined)

    switch (tokenType) {
      case TokenType.Erc20:
        const erc20 = getErc20(tokenAddress, signer)

        if (canUseSafeTx) {
          try {
            const safeTxHash = await proposeMultiGnosisTransaction([
              buildSafeTransaction(
                erc20,
                "delegate",
                [delegateAddress],
                tokenAddress,
              ),
            ])
            onClose()

            if (openPendingTransaction) {
              openPendingTransaction()
            }

            // TODO(@nicolas): The 2nd param is tokenId (even the param name is governanceId) but this is used as metadata
            // In the future this governanceId from useSafeTransaction can be renamed
            onPendingSafeTx(safeTxHash, tokenId, {
              requiredParameters: ["delegateAddress"],
              expectedMethod: "delegate",
            })((values) => handlePendingSafeTx({ ...values, delegateAddress }))
          } catch (error: any) {
            if (error && error.message.includes("user rejected signing")) {
              toast({
                status: "error",
                title: "User rejected signature",
                duration: 5000,
              })
            }
          }
        } else {
          send(() => erc20.delegate(delegateAddress))
        }

        break
      case TokenType.Erc721:
        const erc721 = getErc721(tokenAddress, signer)

        if (canUseSafeTx) {
          try {
            const safeTxHash = await proposeMultiGnosisTransaction([
              buildSafeTransaction(
                erc721,
                "delegate",
                [delegateAddress],
                tokenAddress,
              ),
            ])

            onClose()

            if (openPendingTransaction) {
              openPendingTransaction()
            }

            // TODO(@nicolas): The 2nd param is tokenId (even the param name is governanceId) but this is used as metadata
            // In the future this governanceId from useSafeTransaction can be renamed
            onPendingSafeTx(safeTxHash, tokenId, {
              requiredParameters: ["delegateAddress"],
              expectedMethod: "delegate",
            })((values) => handlePendingSafeTx({ ...values, delegateAddress }))
          } catch (error: any) {
            if (error && error.message.includes("user rejected signing")) {
              toast({
                status: "error",
                title: "User rejected signature",
                duration: 5000,
              })
            }
          }
        } else {
          send(() => erc721.delegate(delegateAddress))
        }

        break
      case TokenType.Erc20Aave:
        // TODO(@nicolas): Added hardcoded contracts to don't break AAVE delegation
        // But AAVE contracts will be uograded
        const aaveTokenAddress = "0x4da27a545c0c5B758a6BA100e3a049001de870f5"
        const aaveStakedAddress = "0x4da27a545c0c5B758a6BA100e3a049001de870f5"

        const aaveToken = getAaveToken(aaveTokenAddress, signer)
        const aaveStaked = getAaveStaked(aaveStakedAddress, signer)

        send(() => aaveToken.delegate(delegateAddress))
        send(() => aaveStaked.delegate(delegateAddress))

        break
      default:
        break
    }
  }

  return {
    error,
    status,
    directDelegation,
  }
}
