import React, { useState, useEffect, useMemo } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Stack,
  Button,
  Text,
  Flex,
  Checkbox,
  Icon,
  HStack,
  Spacer,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Tooltip,
} from "@chakra-ui/react"
import { InfoOutlineIcon } from "@chakra-ui/icons"
import { isAddress } from "@ethersproject/address"
import { useAccount } from "wagmi"
import { BigNumber } from "@ethersproject/bignumber"
import isEqual from "lodash.isequal"
import { ethers } from "ethers"

import UserAvatar from "common/components/UserAvatar"
import type { Account, AssetId, Governor, Organization } from "query/graphql"
import {
  GovernorType,
  TransactionType,
  useAddressDelegateeQuery,
  useDelegateTokenBalancesQuery,
} from "query/graphql"
import { useDirectDelegation } from "delegation/hooks/useDirectDelegation"
import { isSameAddress } from "web3/helpers/address"
import FormInput from "ui/components/form/FormInput"
import { shortString } from "common/helpers/string"
import { useChainReference } from "web3/hooks/useChainReference"
import { ZERO_ADDRESS } from "common/constants/Address"
import { useAddressEligibleFreeDelegation } from "delegation/hooks/useAddressEligibleFreeDelegation"
import { useDirectDelegationBySig } from "delegation/hooks/useDirectDelegationBySig"
import Link from "common/components/Link"
import { ROUTES } from "common/constants/routes"
import ArrowRight from "ui/components/icons/ArrowRight"
import DelegationEligibleMessage from "delegation/components/DelegationEligibleMessage"
import AlertMessage from "common/components/AlertMessage"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import { useSignerStore } from "web3/providers/SignerProvider"
import { useMe } from "user/providers/MeProvider"
import { getDisplayName } from "user/helpers/user"
import { getWeightLabel } from "common/helpers/bignumber"
import { ChevronDown } from "ui/components/icons/font-awesome/ChevronDown"
import { getDelegateOrgSettings } from "delegation/constants/delegate"
import InformationIcon from "ui/components/icons/InformationIcon"
import { isMultiChainTokenIds } from "organization/helpers/organization"
import { getAssetIdParams } from "web3/helpers/assetId"
import { TokenChainIdentity } from "common/components/TokenChainIdentity"
import { useRouter } from "common/hooks/useRouter"
import { getChainByChainId } from "web3/helpers/findChain"
import { getAccountIdParams } from "web3/helpers/accountId"
import {
  useSafeTransaction,
  type FixedSafeMultisigTransactionResponse,
} from "safe/hooks/useSafeTransaction"
import { getOrgToken } from "organization/helpers/org-token"
import {
  FeatureFlag,
  useOrganization,
} from "organization/providers/OrganizationProvider"
import { useTransactionAttempts } from "web3/hooks/useTransactionAttempts"

const DelegateModal = ({
  governors,
  delegateeAddress,
  isWhiteLabel,
  openPendingTransaction,
  account,
  organization,
  tokenIds,
  slug,
  redirectTo,
  buttonLabel,
  balance,
}: {
  governors?: Governor[]
  delegateeAddress?: string
  isWhiteLabel: boolean
  openPendingTransaction?: () => void
  account?: Pick<Account, "address" | "bio" | "name" | "picture" | "twitter">
  organization?: Organization
  tokenIds?: AssetId[]
  slug?: string
  redirectTo?: string
  buttonLabel?: string
  balance?: string
}) => {
  const isMultiChain = isMultiChainTokenIds(tokenIds)
  const { push } = useRouter()
  const { isFeatureFlagOn } = useOrganization()

  const [safeTx, setSafeTx] = useState<
    FixedSafeMultisigTransactionResponse | undefined
  >(undefined)

  const [pendingTransactions, setPendingTransactions] = useState<
    FixedSafeMultisigTransactionResponse[] | undefined
  >(undefined)

  // List of tokenIds to use for delegation
  const [activeTokenIds, setActiveTokenIds] = useState<string[]>([])

  // The current tokenId to use as the delegation chain
  const [tokenId, setTokenId] = useState<string | undefined>(
    isMultiChain ? undefined : tokenIds?.[0],
  )

  const governor = governors?.[0]

  const [displayWhoDelegateButtons, setDisplayWhoDelegateButtons] = useState(
    organization && !delegateeAddress,
  )
  const [isLoading, setIsLoading] = useState(false)

  const { signer } = useSignerStore()
  const { address: walletAddress } = useAccount()
  const chainReference = useChainReference()
  const { directDelegation } = useDirectDelegation({
    chainReference,
    openPendingTransaction,
    onMiningTransaction: () => {
      if (redirectTo) {
        setTimeout(() => {
          push(redirectTo)
        }, 1000)
      }
    },
  })
  const { directDelegationBySig } = useDirectDelegationBySig({
    chainReference,
    onSubmitTransaction: () => {
      if (redirectTo) {
        setTimeout(() => {
          push(redirectTo)
        }, 1000)
      }
    },
  })

  const canOnlyReadDelegate = delegateeAddress !== undefined

  const me = useMe()

  const isMeProfile = me?.address && isSameAddress(me.address, walletAddress)

  const address =
    !isMeProfile && me?.address ? me?.address : walletAddress ?? ""

  const { data: delegateTokens } = useDelegateTokenBalancesQuery(
    {
      input: {
        address,
        tokenId: tokenId ?? "",
      },
    },
    {
      enabled: Boolean(address) && Boolean(tokenId),
    },
  )

  const delegateTokenBalance = useMemo(() => {
    if (!tokenId) {
      return undefined
    }

    const delegateTokenBalance = delegateTokens?.tokenBalances?.find(
      (tokenBalance) =>
        isEqual(tokenBalance.token.id.toLowerCase(), tokenId.toLowerCase()),
    )

    // If token balance is found, return this
    if (delegateTokenBalance) {
      return delegateTokenBalance
    }

    // Otherwise, check out if the token id is hardcoded on the TOKEN_MAPPING
    if (organization) {
      const token = getOrgToken(organization, tokenId)

      if (token) {
        return { balance: 0, token }
      }
    }

    return undefined
  }, [delegateTokens?.tokenBalances, organization, tokenId])

  const tokenInfo = useMemo(
    () => ({
      balance: delegateTokenBalance?.balance,
      decimals: delegateTokenBalance?.token?.decimals,
      symbol: delegateTokenBalance?.token?.symbol,
    }),
    [
      delegateTokenBalance?.balance,
      delegateTokenBalance?.token?.decimals,
      delegateTokenBalance?.token?.symbol,
    ],
  )

  const tokenBalance =
    tokenInfo?.balance && tokenInfo?.decimals
      ? getWeightLabel(BigNumber.from(tokenInfo.balance), tokenInfo.decimals)
      : 0

  const { transactionAttempts } = useTransactionAttempts({
    address,
    transactionType: TransactionType.Delegation,
    tokenId,
  })

  const { data: delegateeData } = useAddressDelegateeQuery(
    {
      input: {
        governorId: governor?.id as string,
        address,
      },
    },
    { enabled: Boolean(governor?.id) && Boolean(address) },
  )

  const { getSafeTransaction, getPendingTransactions } = useSafeTransaction(
    {
      safeId: me?.id as string,
      accountAddress: me?.address,
    },
    { enabled: me?.type === "SAFE" },
  )

  useEffect(() => {
    if (me?.type === "SAFE" && transactionAttempts.length > 0) {
      const { address: safeTxHash } = getAccountIdParams(
        transactionAttempts[0].id,
      )

      getSafeTransaction(safeTxHash).then((safeTx) => {
        setSafeTx(safeTx)
      })

      getPendingTransactions().then((results) => {
        setPendingTransactions(results)
      })
    }
  }, [
    getSafeTransaction,
    getPendingTransactions,
    me?.address,
    me?.type,
    transactionAttempts,
  ])

  const pendingSafeTx = pendingTransactions
    ? pendingTransactions.find((pendingTx) => {
        if (pendingTx.safeTxHash === safeTx?.safeTxHash) {
          return pendingTx
        }
      })
    : undefined

  const name = account?.name
  const picture = account?.picture
  const twitterUsername = account?.twitter
  const isVerifiedByTwitter = Boolean(twitterUsername)
  const USER_IDENTITY_HEIGHT = "2.625rem"

  const displayName = account ? getDisplayName(account) : ""

  // If delegateeAddress is a contract, then filter all the chains (tokenIds) where this contract exists
  // Otherwise, use all the chains (tokenIds)
  useEffect(() => {
    if (!tokenIds) {
      return
    }

    if (!isMultiChain) {
      setActiveTokenIds(tokenIds)

      return
    }

    if (!delegateeAddress) {
      setActiveTokenIds(tokenIds)
      setTokenId(tokenIds[0])

      return
    }

    const checkContractOnChains = async () => {
      type ChainInfo = {
        tokenId: string
        isContract: boolean
      }
      const fetchData = async (tokenId: string): Promise<ChainInfo> => {
        try {
          const { chainId } = getAssetIdParams(tokenId)
          const foundChain = getChainByChainId(chainId)

          const networkProvider = new ethers.providers.JsonRpcProvider(
            process.env[foundChain?.envRPCArg as string] as string,
          )
          const code = await networkProvider.getCode(delegateeAddress)
          const isContract = code !== "0x"

          return { tokenId, isContract }
        } catch (err) {
          return { tokenId, isContract: false }
        }
      }

      const promises = tokenIds.map(fetchData)

      const results = await Promise.all(promises)

      if (!results) {
        setActiveTokenIds(tokenIds)
        setTokenId(tokenIds[0])

        return
      }

      // If the delegateeAddress is a contract, then filter all the tokenIds (chains) where this contract exists
      // Otherwise, just set set the full list of tokenIds
      const hasTrueContract = results.some((item) => item.isContract === true)
      const _activeTokenIds = hasTrueContract
        ? results
            .filter((item) => item.isContract === true)
            .map((item) => item.tokenId)
        : results.map((item) => item.tokenId)

      setActiveTokenIds(_activeTokenIds)
      setTokenId(_activeTokenIds[0])
    }

    checkContractOnChains()
  }, [address, delegateeAddress, isMultiChain, tokenIds])

  const FAILED_VALIDATION_MESSAGE = "Please enter a valid ETH address"
  const {
    errors,
    handleChange,
    touched,
    values,
    handleSubmit,
    setFieldValue,
    isValid,
    validateForm,
  } = useFormik<{
    tokenAddress?: string
    delegateeAddress: string
    governanceId?: string
  }>({
    initialValues: {
      tokenAddress: undefined,
      delegateeAddress: "",
      governanceId: undefined,
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      delegateeAddress: Yup.string().test({
        message: FAILED_VALIDATION_MESSAGE,
        test: (value) => (value ? isAddress(value) : false),
      }),
      tokenAddress: Yup.string().test({
        message: FAILED_VALIDATION_MESSAGE,
        test: (value) => (value ? isAddress(value) : false),
      }),
    }),
    onSubmit({ delegateeAddress }) {
      if (eligibleData.addressEligible && isUseFreeDelegation) {
        setIsLoading(true)
        directDelegationBySig({
          delegateeAddress,
          signer,
          tokenId,
          organizationId: organization?.id as string,

          // TODO(@nicolas): Dont use hardcoded value
          governorType: GovernorType.Openzeppelingovernor,
        }).finally(() => {
          setIsLoading(false)
        })
      } else {
        setIsLoading(true)
        directDelegation({
          delegateAddress: delegateeAddress,
          signer,
          tokenId,
        }).finally(() => {
          setIsLoading(false)
        })
      }
    },
  })

  useEffect(() => {
    if (!delegateeAddress) return

    setFieldValue("delegateeAddress", delegateeAddress)

    // Force revalidate the form after setting the delegatee address
    setTimeout(() => {
      validateForm()
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delegateeAddress, setFieldValue])

  useEffect(() => {
    if (!tokenId) {
      return
    }

    const { address } = getAssetIdParams(tokenId)

    setFieldValue("tokenAddress", address)
  }, [tokenId, setFieldValue])

  const eligibleData = useAddressEligibleFreeDelegation({
    organizationId: organization?.id ?? "",
    tokenId: tokenId as string,
    address,
  })

  const [isUseFreeDelegation, setIsUseFreeDelegation] = useState(false)

  useEffect(() => {
    if (eligibleData.addressEligible) {
      setIsUseFreeDelegation(true)
    }
  }, [eligibleData])

  const handleDelegateMyself = () => {
    setFieldValue("delegateeAddress", address).then(() => {
      handleSubmit()
    })
  }

  const handleDelegateAddress = (delegateAddress: string) => {
    setFieldValue("delegateeAddress", delegateAddress).then(() => {
      handleSubmit()
    })
  }

  const votingPowerLabel =
    tokenBalance !== undefined && Boolean(tokenInfo?.symbol)
      ? `${tokenBalance} ${tokenInfo?.symbol}`
      : null

  const displayExploreDelegates = Boolean(!displayWhoDelegateButtons && slug)

  const displayRecentDelegationAttempt =
    transactionAttempts && Boolean(transactionAttempts?.length > 0)

  const delegateOrgSettings = organization
    ? getDelegateOrgSettings(organization.id)
    : undefined

  const useWormholeTokenBridgeDelegation = isFeatureFlagOn(
    FeatureFlag.UseWormholeTokenBridgeDelegation,
  )

  return (
    <Stack
      align="center"
      borderColor="purple.400"
      pb={4}
      pt={4}
      px={8}
      spacing={6}
    >
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Stack spacing={4}>
          {isMultiChain ? (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={
                  <Icon as={ChevronDown} color="gray.600" h={3} w={3} />
                }
                variant="outline"
              >
                <HStack width="100%">
                  <Text color="gray.600" fontWeight="bold">
                    Network
                  </Text>
                  <Spacer />
                  {tokenId ? (
                    <TokenChainIdentity tokenId={tokenId} />
                  ) : (
                    <Text fontWeight="normal">Loading...</Text>
                  )}
                </HStack>
              </MenuButton>

              {activeTokenIds.length > 0 ? (
                <MenuList width={{ base: "300px", md: "385px" }} zIndex={500}>
                  <MenuOptionGroup
                    defaultValue={tokenId}
                    onChange={(tokenId) => {
                      if (tokenId) {
                        setTokenId(tokenId as string)
                      }
                    }}
                  >
                    {activeTokenIds?.map((tokenId, idx) => (
                      <MenuItemOption key={`tokenid-${idx}`} value={tokenId}>
                        <TokenChainIdentity tokenId={tokenId} />
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              ) : null}
            </Menu>
          ) : null}

          {displayWhoDelegateButtons ? (
            <>
              <Button variant="secondary" onClick={handleDelegateMyself}>
                <Text fontWeight="500" textStyle="md">
                  Myself
                </Text>
              </Button>
              <Button
                variant="secondary"
                onClick={() => setDisplayWhoDelegateButtons(false)}
              >
                <Text fontWeight="500" textStyle="md">
                  Someone else
                </Text>
              </Button>
              {delegateOrgSettings?.buttons &&
                delegateOrgSettings.buttons.map((button, idx) => (
                  <Stack key={`button-${idx}`} spacing={2}>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        handleDelegateAddress(button.delegateAddress)
                      }
                    >
                      <Text fontWeight="500" textStyle="md">
                        {button.label}
                      </Text>
                    </Button>
                    {button.moreInfoUrl ? (
                      <Flex>
                        <Link isExternal href={button.moreInfoUrl}>
                          <HStack justify="baseline">
                            <Icon
                              as={InformationIcon}
                              color="gray.500"
                              h={4}
                              w={4}
                            />
                            <Text color="gray.500" fontSize="sm">
                              How {button.label.toLowerCase()} works
                            </Text>
                          </HStack>
                        </Link>
                      </Flex>
                    ) : null}
                  </Stack>
                ))}
            </>
          ) : null}

          {!displayWhoDelegateButtons && !delegateeAddress ? (
            <FormInput
              shouldShowValidationIcon
              dataQa="delegatemodal-enter-eth-address-input"
              errors={errors}
              inputProps={{ size: "sm", h: "10", focusBorderColor: "" }}
              isReadOnly={canOnlyReadDelegate}
              label="Address"
              name="delegateeAddress"
              placeholder="Enter an ETH address"
              touched={touched}
              values={values}
              onChange={handleChange}
            />
          ) : null}

          {delegateeAddress && Boolean(displayName) ? (
            <Stack
              isInline
              align="center"
              alignItems="center"
              h={USER_IDENTITY_HEIGHT}
              spacing={2}
            >
              <UserAvatar
                address={delegateeAddress}
                isVerifiedByTwitter={isVerifiedByTwitter}
                size="sm"
                src={picture}
              />
              <Flex direction="column" justify="center">
                <Text textStyle="md">
                  {isAddress(displayName)
                    ? shortString(displayName)
                    : displayName}
                </Text>
              </Flex>
            </Stack>
          ) : null}

          {displayRecentDelegationAttempt && me?.type === "EOA" ? (
            <RecentDelegationWarning />
          ) : null}

          {displayRecentDelegationAttempt &&
          me?.type === "SAFE" &&
          pendingSafeTx &&
          !pendingSafeTx?.isExecuted ? (
            <PendingSafeDelegationWarning />
          ) : null}

          {address && values.delegateeAddress && governor && (
            <CurrentDelegationState
              delegatee={values.delegateeAddress}
              delegatingAddress={delegateeData?.delegatee?.delegate?.address}
              meAddress={address}
            />
          )}

          {eligibleData?.sponsorshipSupported ? (
            <Stack
              align="flex-start"
              direction={useWormholeTokenBridgeDelegation ? "column" : "row"}
              spacing={1}
            >
              {eligibleData?.addressEligible ? (
                <>
                  <Stack
                    alignItems="center"
                    direction="row"
                    maxWidth={useWormholeTokenBridgeDelegation ? "100%" : "50%"}
                  >
                    <Checkbox
                      isChecked={isUseFreeDelegation}
                      name={name}
                      onChange={(e) => setIsUseFreeDelegation(e.target.checked)}
                    >
                      <Text fontWeight="500" textStyle="sm">
                        {useWormholeTokenBridgeDelegation
                          ? "Use free gas to stake for governance"
                          : "Use free delegation"}
                      </Text>
                    </Checkbox>
                    <Tooltip label="Ledger doesn't support free delegations">
                      <InfoOutlineIcon color="gray.400" />
                    </Tooltip>
                  </Stack>
                  <Spacer />
                </>
              ) : null}

              <DelegationEligibleMessage
                displayShortMessage
                addressEligible={eligibleData.addressEligible}
                freeDelegationsAvailable={eligibleData.freeDelegationsAvailable}
                justify="flex-end"
              />
            </Stack>
          ) : null}

          {!displayWhoDelegateButtons ? (
            <>
              <Button
                data-qa="delegatemodal-delegate-votes-btn"
                isDisabled={!isValid}
                isLoading={isLoading}
                type="submit"
                variant="primary"
              >
                <Text fontWeight="500" textStyle="md">
                  {buttonLabel ?? "Delegate votes"}{" "}
                  {balance ?? votingPowerLabel}
                </Text>
              </Button>
              {isLoading ? (
                <Text align="center" fontSize="sm">
                  Complete transaction in wallet
                </Text>
              ) : null}
            </>
          ) : null}

          {displayExploreDelegates && slug ? (
            <ExploreDelegates isWhiteLabel={isWhiteLabel} slug={slug} />
          ) : null}
        </Stack>
      </form>
    </Stack>
  )
}

export default DelegateModal

const CurrentDelegationState = ({
  delegatingAddress,
  meAddress,
  delegatee,
}: {
  delegatingAddress?: string
  meAddress: string
  delegatee: string
}) => {
  if (!delegatingAddress || delegatingAddress === ZERO_ADDRESS) return null

  if (isSameAddress(delegatingAddress, delegatee)) {
    return (
      <AlertMessage isDisplayingIcon={false} status="info">
        {isSameAddress(meAddress, delegatee)
          ? "You are currently delegating to yourself"
          : "You are already delegating to this address"}
      </AlertMessage>
    )
  }

  return null
}

const ExploreDelegates = ({
  isWhiteLabel,
  slug,
}: {
  isWhiteLabel: boolean
  slug: string
}) => {
  return (
    <>
      <Divider />
      <Link
        isExternal
        href={
          isWhiteLabel
            ? getWhiteLabelRoute(ROUTES.governance.delegates(slug))
            : ROUTES.governance.delegates(slug)
        }
      >
        <Button
          color="gray.600"
          data-qa="delegatemodal-explorealldelegates-btn"
          rightIcon={<Icon as={ArrowRight} color="gray.600" />}
          variant="link"
          width="100%"
        >
          <Text fontWeight="500" textStyle="md">
            Explore all delegates
          </Text>
        </Button>
      </Link>
    </>
  )
}

const RecentDelegationWarning = () => {
  return (
    <AlertMessage isDisplayingIcon={false} status="info">
      Note: You recently submitted a delegation. It may still be processing.
    </AlertMessage>
  )
}

const PendingSafeDelegationWarning = () => {
  return (
    <AlertMessage isDisplayingIcon={false} status="info">
      Note: You recently submitted a delegation to your Safe. It may still be
      waiting for signers.
    </AlertMessage>
  )
}
