import { useState } from "react"
import type { FetchSignerResult, Signer } from "@wagmi/core"
import { isAddress } from "@ethersproject/address"
import { ethers } from "ethers"
import { useSwitchNetwork } from "wagmi"

import { TokenType } from "query/graphql"
import type { GovernorType } from "query/graphql"
import type { UseCorrectChainErrors } from "web3/helpers/chain"
import { getCorrectChain } from "web3/helpers/chain"
import { getChainIdParams } from "web3/helpers/chainId"
import { getChainReferenceName } from "web3/helpers/chainReference"
import { getErc20 } from "web3/hooks/useErc20"
import { useToast } from "common/hooks/useToast"
import { useNavegableModal } from "common/hooks/useNavegableModal"
import { useSignTransaction } from "web3/hooks/useSignTransaction"
import { useRelayer } from "web3/hooks/useRelayer"
import { UseDirectDelegationErrors } from "delegation/constants/delegation-error"
import { getAssetIdParams } from "web3/helpers/assetId"

type Error = UseCorrectChainErrors | UseDirectDelegationErrors

type Values = {
  error?: Error
  directDelegationBySig: (values: {
    signer?: FetchSignerResult<Signer>
    tokenId?: string
    governorType: GovernorType
    delegateeAddress?: string
    organizationId: string
  }) => Promise<void>
}

type Props = {
  chainReference?: number
  onSubmitTransaction?: () => void
}

export const useDirectDelegationBySig = ({
  chainReference,
  onSubmitTransaction,
}: Props): Values => {
  const [error, setError] = useState<Error | undefined>(undefined)

  const { toast } = useToast()
  const { onClose } = useNavegableModal()
  const { signDelegateTransaction } = useSignTransaction({
    chainId: chainReference,
  })
  const { switchNetwork } = useSwitchNetwork()
  const { submitDelegateTransaction: submitTransaction } = useRelayer()

  const directDelegationBySig = async ({
    organizationId,
    signer,
    tokenId,
    delegateeAddress,
    governorType,
  }: {
    organizationId: string
    signer?: FetchSignerResult<Signer>
    tokenId?: string
    governorType: GovernorType
    delegateeAddress?: string
  }): Promise<void> => {
    if (!tokenId) {
      setError(UseDirectDelegationErrors.Undefinedtokend)

      return
    }

    const {
      chainId,
      tokenType,
      address: tokenAddress,
    } = getAssetIdParams(tokenId)

    const { reference } = getChainIdParams(chainId)
    const chainReferenceName = getChainReferenceName(reference)

    if (!signer) {
      setError(UseDirectDelegationErrors.Signernotfound)

      toast({
        status: "warning",
        title: "Wallet disconnected",
        description: `You must connect your wallet to delegate`,
        duration: 5000,
      })

      return
    }

    if (!delegateeAddress || !isAddress(delegateeAddress)) {
      setError(UseDirectDelegationErrors.Invaliddelegateeaddress)

      toast({
        status: "warning",
        title: "Invalid address",
        description: "The address you are trying to delegate to is invalid",
        duration: 5000,
      })

      return
    }

    const { isCorrectChain, error: useCorrectChainErrors } = getCorrectChain({
      chainId,
      chainReference,
    })

    if (!isCorrectChain) {
      setError(UseDirectDelegationErrors.Nonmatchingchain)

      toast({
        status: "warning",
        title: "Incorrect network",
        description: `You must be connected to the ${chainReferenceName} network to delegate on this governace`,
        duration: 5000,
      })

      switchNetwork?.(reference)

      return
    }

    if (useCorrectChainErrors !== undefined) {
      setError(useCorrectChainErrors)

      throw new Error(
        `useDirectDelegation => "chainId" or "chainReference" or both, are incorrect.
           This should not be happening as they are being gathered from the server and the provider`,
      )
    }

    if (!isAddress(tokenAddress)) {
      setError(UseDirectDelegationErrors.Invalidtokenaddress)

      return
    }

    setError(undefined)

    switch (tokenType) {
      case TokenType.Erc20:
        const erc20 = getErc20(tokenAddress, signer)

        const fromAddress = await signer.getAddress()
        const nonce = await erc20.nonces(fromAddress)

        const expiry = Math.floor(Date.now() / 1000) + 60 * 60 * 24 // 24 hours

        const signature = await signDelegateTransaction({
          contractAddress: erc20.address,
          contractName: await erc20.name(),
          delegateeAddress,
          nonce,
          expiry,
          governorType,
        })

        if (signature) {
          const { v, r, s } = ethers.utils.splitSignature(signature)

          const gasLimit = await erc20.estimateGas.delegateBySig(
            delegateeAddress,
            nonce.toNumber(),
            expiry,
            v,
            r,
            s,
          )

          const data = erc20.interface.encodeFunctionData("delegateBySig", [
            delegateeAddress,
            nonce.toNumber(),
            expiry,
            v,
            r,
            s,
          ])

          return submitTransaction({
            organizationId,
            tokenId,
            address: fromAddress,
            delegateeAddress,
            contractAddress: erc20.address,
            data,
            gasLimit: gasLimit.toNumber(),
            chainId,
            contractName: await erc20.name(),
            signature,
            nonce: nonce.toNumber(),
            expiry,
          }).then((data) => {
            // TODO(@nicolas): Save direct delegation in analytics?
            // saveDirectDelegationBySig({})

            if (data !== undefined) {
              onClose()
            }

            if (onSubmitTransaction) {
              onSubmitTransaction()
            }
          })
        }

        break
      case TokenType.Erc721:
        // TODO(@nicolas): To be implemented in the future

        break
      case TokenType.Erc20Aave:
        // TODO(@nicolas): To be implemented in the future

        break
      default:
        break
    }
  }

  return {
    error,
    directDelegationBySig,
  }
}
