import type { Signer } from "@ethersproject/abstract-signer"
import { isAddress } from "@ethersproject/address"
import type { Provider } from "@ethersproject/providers"

import type { ERC20 } from "contracts/bindings/ERC20"
import { ERC20__factory as ERC20Factory } from "contracts/bindings/factories/ERC20__factory"

export const getErc20 = (
  address: string,
  signerOrProvider: Signer | Provider,
): ERC20 => ERC20Factory.connect(address, signerOrProvider)

export const useErc20 = (
  address: string,
  signerOrProvider?: Signer | Provider,
): ERC20 | undefined => {
  if (!isAddress(address) || !signerOrProvider) return

  return getErc20(address, signerOrProvider)
}
